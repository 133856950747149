<template>
  <footer class="footer">
    <div class="footer__top container">
      <div class="flex flex-wrap md:border-t md:border-t-primary">
        <!-- Logo -->
        <div
          class="flex justify-center md:justify-start flex-grow order-1 md:order-0 w-full md:w-1/3 pr-4 py-6"
        >
          <nuxt-link to="/">
            <InlineSvg
              class="footer__logo text-primary h-6"
              :src="logoSrc"
              :alt="concept"
            />
          </nuxt-link>
        </div>

        <!-- Links -->
        <div
          class="w-full md:w-2/3 footer__links order-0 md:order-1 border-y border-y-primary md:border-y-0"
        >
          <ul class="flex flex-col md:flex-row gap-y-4 py-7 md:flex-wrap">
            <li
              v-for="column in columns"
              :key="column.title"
              class="link sm:w-1/3"
            >
              <nuxt-link class="flex" :to="column.links[0].to">
                <div>
                  <ArrowLink class="h-5 w-5 pt-1" />
                </div>
                <div class="pl-1">
                  <h3
                    class="link__title text-normal font-semibold leading-6 text-lg"
                  >
                    {{ column.title }}
                  </h3>
                  <p class="link__list text-sm mt-1 leading-5 hover:underline">
                    {{ column.links[0].text }}
                  </p>
                </div>
              </nuxt-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <slot />

    <div class="footer__bottom bg-gray-100 py-3 text-xs text-darkgrey">
      <div class="container lg-down:max-w-full">
        <div class="flex flex-wrap sm-down:flex-col-reverse">
          <div class="flex-grow sm-down:mt-2">
            © {{ new Date().getFullYear() }}
            <a
              class="hover:underline"
              :href="$t('global.companyLink')"
              target="_blank"
              >Bambelo B.V.</a
            >
          </div>

          <div
            class="flex flex-wrap sm-down:flex-col sm-down:space-y-2 text-left md:text-right"
          >
            <nuxt-link
              class="hover:underline"
              :to="$t('global.privacyPolicyLink')"
            >
              {{ $t('global.privacyPolicy') }}
            </nuxt-link>
            <span class="mx-1 sm-down:hidden">|</span>
            <nuxt-link
              class="hover:underline"
              :to="$t('global.cookiePolicyLink')"
            >
              {{ $t('global.cookiePolicy') }}
            </nuxt-link>
            <span class="mx-1 sm-down:hidden">|</span>
            <nuxt-link
              class="hover:underline"
              :to="$t('global.termsAndConditionsLink')"
            >
              {{ $t('global.termsAndConditions') }}
            </nuxt-link>
            <hr
              class="md:hidden opacity-50 border-[#9CA3AF] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import usesFeatureFlag from 'chimera/all/mixins/usesFeatureFlag'
import ArrowLink from 'chimera/all/themes/jupiter/images/icons/IconArrowLink'

export default {
  name: 'Footer',

  components: {
    ArrowLink,
    InlineSvg,
  },

  mixins: [usesFeatureFlag],

  props: {
    logoSrc: {
      type: String,
      default: 'images/logo.svg',
    },

    columns: {
      type: Array,
      default: () => [
        {
          title: 'Over ons',
          links: [
            {
              text: 'Contact',
              to: '/contact-opnemen',
            },
          ],
        },
        {
          title: 'Professionals',
          links: [
            {
              text: 'Aanmelden als pro',
              to: '/contact-opnemen-bedrijf',
            },
          ],
        },
      ],
    },
  },

  /**
   * @returns {{concept: string}}
   */
  data() {
    return {
      concept: process.env.concept,
    }
  },
}
</script>
