<template>
  <svg fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g
      clip-path="url(#a)"
      clip-rule="evenodd"
      fill="currentColor"
      fill-rule="evenodd"
    >
      <path
        d="m10 17.5c4.1421 0 7.5-3.3578 7.5-7.5 0-4.1421-3.3579-7.5-7.5-7.5-4.1422 0-7.5 3.3579-7.5 7.5 0 4.1422 3.3579 7.5 7.5 7.5zm9.1666-7.5c0 5.0626-4.104 9.1667-9.1666 9.1667-5.0626 0-9.1667-4.1041-9.1667-9.1667 0-5.0626 4.1041-9.1666 9.1667-9.1666 5.0626 1e-6 9.1666 4.1041 9.1666 9.1666z"
      />
      <path
        d="m9.4108 13.923c-0.32543-0.3254-0.32543-0.8531 0-1.1785l2.7441-2.7441-2.7441-2.744c-0.32544-0.32544-0.32544-0.85308 0-1.1785 0.32543-0.32544 0.85307-0.32544 1.1785 0l3.3334 3.3333c0.3254 0.32544 0.3254 0.85303 0 1.1785l-3.3334 3.3333c-0.3254 0.3255-0.85304 0.3255-1.1785 0z"
      />
      <path
        d="m14.167 9.9997c0 0.46023-0.373 0.83333-0.8333 0.83333h-6.6666c-0.46024 0-0.83333-0.3731-0.83333-0.83333s0.37309-0.83333 0.83333-0.83333h6.6666c0.4603 0 0.8333 0.3731 0.8333 0.83333z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect
          transform="translate(20 20) rotate(180)"
          width="20"
          height="20"
          fill="none"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconArrowLink',
}
</script>
