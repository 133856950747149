<template>
  <header class="header">
    <div class="absolute inset-0">
      <img src="images/hero.jpg" alt="" class="object-cover h-full w-full" />
    </div>
    <div class="overlay absolute inset-0" />

    <div class="container relative flex justify-center">
      <div class="md:max-w-[420px]">
        <div
          class="text-center md:text-left pt-[16vh] pb-[12vh] md:pt-[30vh] md:pb-0"
        >
          <i18n
            tag="h1"
            path="title"
            class="text-white font-semibold text-2xl md:text-4xl leading-8"
            style="text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)"
          >
            <template #service>
              <span class="text-primary">
                {{ $t('title-service') }}
              </span>
            </template>
          </i18n>

          <h2 class="mt-5 text-white font-semibold leading-5">
            {{ $t('subtitle') }}
          </h2>
        </div>

        <div class="md:mt-12">
          <div class="header__container">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Check je {service} bij een makelaar",
    "title-service": "woningwaarde",
    "subtitle": "Heb je verhuisplannen en overweeg je jouw huis te verkopen? Makelaars geven je graag inzicht in de huidige (over)waarde van jouw woning."
  },
  "en-GB": {
    "title": "Get your {service} from an expert",
    "title-service": "free house valuation",
    "subtitle": "We match the right real estate specialists to your needs."
  }
}
</i18n>
